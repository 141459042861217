import { Navigate, Outlet } from "react-router-dom";
import { ILoggedUser } from "../../models"

interface IProps{
    user?: ILoggedUser;
    redirect?: string;
}

const PrivateRoute : React.FC<IProps> = ({user,redirect = '/'}) => {
    if(!user)
        return <Navigate to={redirect} replace />
    return <Outlet />
}

export default PrivateRoute