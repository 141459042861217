import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { CrudHeader } from "../components/common"
import { DeviceGrid } from "../components/Devices"
import { useStore } from "../stores/store"

const DevicesPage = () => {
  const {devicesStore:{deviceList,loadDevices,setSearchTerm,setDevSortColumn},commonStore:{setPrevRoute}} = useStore()

  useEffect(()=>{
    setPrevRoute(window.location.pathname);
    if(deviceList.length === 0)
      loadDevices();
    setDevSortColumn(2);
  },[]);

  const handleSearch = (search: string) => {
    if(search.length > 0)
      setSearchTerm(search);
  }  
  return (
    <>
      <h2 className="main-contents-heading">Devices</h2>
      <CrudHeader handleSearch={handleSearch} placeholder='Mobile No' showNew={false} />
      <DeviceGrid devices={deviceList} />
    </>
  )
}

export default observer(DevicesPage);