import { observer } from "mobx-react-lite";
import { IRequestItem } from "../../models"
import { ToDDMMYY } from "../../utils/Utils";

interface IProps{
    request: IRequestItem;
}

const RequestsSubGridRow : React.FC<IProps> = ({request}) => {
  return (
    <>
        <div key={`${request.id}_1`} className='data-grid-col'>{ToDDMMYY(new Date(request.createdOn))}</div>
        <div key={`${request.id}_2`} className='data-grid-col'>{request.mobileNo}</div>
        <div key={`${request.id}_3`} className='data-grid-col'>{request.appName}</div>
        <div key={`${request.id}_4`} className='data-grid-col'>{request.type}</div>
        <div key={`${request.id}_5`} className='data-grid-col'>{request.status}</div>
        <div key={`${request.id}_6`} className='data-grid-col'>{request.processedOn ? ToDDMMYY(new Date(request.processedOn)) : 'Not Processed'}</div>
    </>
  )
}

export default  observer(RequestsSubGridRow);