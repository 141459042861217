import {FiSearch} from 'react-icons/fi';
import {BiImport, BiPlus} from 'react-icons/bi'
interface IProps{
    placeholder?:string;
    handleSearch: (search: string) => void;
    showNew?: boolean;
    handleNew?: () => void;
}
const CrudHeader : React.FC<IProps> = ({
    placeholder = "Search...",
    handleSearch,
    showNew = true,
    handleNew,
}) => {
  return (
    <div className='panel'>
        <div className='search-box'>
            <FiSearch className='search-box-icon' />
            <input type="search" placeholder={placeholder} onChange={(event) => handleSearch(event.target.value)} className='search-box-input'></input>
        </div>
        <div className='search-box-buttons'>
            {showNew && handleNew && <button className='btn btn-with-icon btn-default' onClick={handleNew} ><BiPlus />New</button>}
        </div>
    </div>
  )
}

export default CrudHeader