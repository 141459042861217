import { useState } from 'react';
import {FiSearch} from 'react-icons/fi';
import { IRequestSearchParams } from '../../models';
interface IProps{
    handleSearch: (search: IRequestSearchParams) => void;
}
const RequestsSearchBox : React.FC<IProps> = ({
    handleSearch
}) => {
    const [searchParams, setSearchParams] = useState<IRequestSearchParams>({appName:'',mobileNo:''});
  return (
    <div className='panel'>
        <div className='request-search-box'>
            <div className='request-search-input'>
                <FiSearch className='search-box-icon' />
                <input name='appName' type="appName" placeholder='App Name' value={searchParams.appName} onChange={(event) => setSearchParams({...searchParams,appName:event.target.value})} className='request-box-input'></input>
            </div>
            <div className='request-search-input'>
                <FiSearch className='search-box-icon' />
                <input name='mobileNo' type="mobileNo" placeholder='Mobile No' value={searchParams.mobileNo} onChange={(event) => setSearchParams({...searchParams,mobileNo:event.target.value})} className='request-box-input'></input>
            </div>
            <div className='request-search-options'>
                <div className='request-search-checkbox'>
                    <input name='showCancelled' type='checkbox' onChange={(event)=> setSearchParams({...searchParams,showCancelled:event.target.checked})} value={searchParams.showCancelled ? 1 : 0} />
                    <label>Show Cancelled</label>
                </div>
                <div className='request-search-checkbox'>
                    <input name='showProcessed' type='checkbox' onChange={(event)=> setSearchParams({...searchParams,showProcessed:event.target.checked})} value={searchParams.showProcessed ? 1 : 0} />
                    <label>Show Processed</label>
                </div>
            </div>
            <button type='button' className='btn btn-default' onClick={()=>handleSearch(searchParams)} >Filter</button>
        </div>
    </div>
  )
}

export default RequestsSearchBox