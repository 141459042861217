import { ReactNode } from "react";
import { FaTimesCircle } from "react-icons/fa";

interface IProps{
    children?: ReactNode 
    title: string;
    showDialog: boolean;
    onClose: () => void;
    hasScroll?: boolean;
}
const InfoDialog: React.FC<IProps> = ({children, title,showDialog,onClose,hasScroll}) => {
  return (
    <div className={`dialog-wrapper ${showDialog ? "dialog-show" : ""}`}>
        <div className='dialog-box'>
            <div className="dialog-title">
                <h2 data-alter-title>{title}</h2>
                <span className="dialog-close">
                    <FaTimesCircle onClick={onClose} />
                </span>
            </div>
            <div className={`dialog-body  ${hasScroll ? "overflow-scroll" : ""}`}>
                {children && children}
            </div>
            <div className="dialog-footer">
                <button className="btn btn-with-icon btn-cancel" onClick={onClose}>Close</button>
            </div>
        </div>
    </div>
  )
}

export default InfoDialog