import { Formik, Form, ErrorMessage } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { IAppDatabaseEntity, IAppModuleEntity, IAppsEntity, ILicenseKeyParams, IRequestItem } from "../../models" 
import { useStore } from "../../stores/store";
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'
import "react-datepicker/dist/react-datepicker.css";
import { addYear } from "../../utils/Utils";
import * as Yup from 'yup';
import { FaSearch } from "react-icons/fa";
import LocationLookUp from "./LocationLookUp";

interface IProps{
    request: IRequestItem;
    showDialog: boolean;
    hideDialog: () => void;
}
const RequestProcessDialog : React.FC<IProps> = ({
    request,
    showDialog,
    hideDialog
}) => {
    registerLocale('en-GB',enGB);
    setDefaultLocale('en-GB');

    const {appsStore:{appModules,appDbs,drivers,loadAppModules,loadAppDbs, loadingDbs, loadingModules, loadingDrivers, getAppModules,getAppDbs,getDrivers,resetDrivers,getApp,locations,loadingLocations,getLocations,resetLocations},devicesStore:{generateLicense}} = useStore()
    const [licenseValues, setLicenseValues] = useState<ILicenseKeyParams>({
        requestId: request.id,
        databaseId: 0,
        driverId: 0,
        driverName: '',
        extras: '',
        shopCode: '',
        validFrom: new Date(),
        validTill: addYear(new Date()),
        modules: []
    })
    const [databases, setDatabases] = useState<IAppDatabaseEntity[]>([]);
    const [modules, setModules] = useState<IAppModuleEntity[]>([]);
    const [currentApp, setCurrentApp] = useState<IAppsEntity|undefined>();
    const [showLookUp, setShowLookUp] = useState(false);
    useEffect(()=>{
        resetLocations();
    },[])
    useEffect(()=>{
        if(request.appId){
           setCurrentApp(getApp(request.appId))
        }
    },[request.appId,getApp])
    useEffect(()=>{
        if(appDbs.length > 0)
            setDatabases(getAppDbs(request.appId));
        else
            loadAppDbs();
    },[appDbs,setDatabases,getAppDbs,request.appId,loadAppDbs]);
    useEffect(()=>{
        if(appModules.length > 0)
            setModules(getAppModules(request.appId));
        else
            loadAppModules();
    },[appModules,setModules,getAppModules,request.appId,loadAppModules])

    const validationSchema = Yup.object({
          databaseId: Yup.number().required('Kindly select database').min(1,'Kindly select database'),
          driverId: Yup.number().required('Kindly select a driver'),
          extras: Yup.string().max(50,"Extras cannot be more than 50 characters"),
          shopCode: Yup.string().notRequired().min(3, 'Shop code must be 3 characters only').max(3,'Shop code must be 3 characters only'),
          validFrom: Yup.date().required('Kindly provide validity dates'),
          validTill: Yup.date().required('Kindly provide validtiy dates')
    });
    const handleOnDbChange = (id: number) => {
        if(id === 0){
            resetDrivers();
            resetLocations();
        }
        setLicenseValues({...licenseValues,databaseId:id,extras:''});
        let database = databases.find(d => d.id === id)
        if(database){
            getDrivers(database.databaseName);
            if(currentApp?.showExtras)
                getLocations(database.databaseName);
        }
        else{
            resetDrivers();
            resetLocations();
        }
    }
    const handleOnDriverChange = (id: number) => {
        if(id > 0){
            var driver = drivers.find(d => d.driverId === id)
            if(driver)
                setLicenseValues({...licenseValues, driverId: driver.driverId, driverName: driver.driverName});
        }
    }
    const handleOnLocationSelected = (uid: string) => {
        setLicenseValues({...licenseValues, extras: uid})
        setShowLookUp(false);
    }
  return (
    <>
    <Formik 
        initialValues={licenseValues}
        validationSchema={validationSchema} 
        enableReinitialize={true}
        onSubmit={(values)=>{generateLicense({...values,modules: [...values.modules.map(m => Number(m))] }); hideDialog();}}>{
            ({handleSubmit,handleChange,handleBlur,isSubmitting,values,setFieldValue}) => (
                <Form onSubmit={handleSubmit}>
                    <div className={`dialog-wrapper ${showDialog ? "dialog-show" : ""}`}>
                        <div className='dialog-box'>
                            <div className="dialog-title">
                                <h2 data-alter-title>{`Generate License for Mobile No: ${request.mobileNo}`}</h2>
                                <span className="dialog-close">
                                    <FaTimesCircle onClick={hideDialog} />
                                </span>
                            </div>
                            <div className='dialog-body'>
                                <div className="form-input-group">
                                    <label htmlFor='databaseId' className="form-input-label">Database</label>
                                    <select name='databaseId' value={values.databaseId} className='form-input-field' onBlur={handleBlur} onChange={(event) => 
                                                {
                                                    handleOnDbChange(parseInt(event.target.value));
                                                    handleChange(event);
                                                }}>
                                        {!loadingDbs ? (
                                            <>
                                                <option key={0} value={0}>Select Database</option>
                                                {databases.map((db) => (
                                                    <option key={db.id} value={db.id}>{db.databaseName}</option>
                                                ))}
                                            </>
                                        ) : (
                                                <option key={0} value={0}>Loading...</option>
                                        )}
                                    </select>
                                    <ErrorMessage name='databaseId' component='span' className='form-input-row-error' />
                                </div>
                                {currentApp?.showDriver && (
                                    <div className="form-input-group">
                                        <label htmlFor='driverId' className="form-input-label">Driver</label>
                                        <select name='driverId' value={values.driverId} className='form-input-field' onBlur={handleBlur} onChange={(event) => 
                                                    {
                                                        handleOnDriverChange(parseInt(event.target.value));
                                                        handleChange(event);
                                                    }}>
                                                    {!loadingDrivers ? (
                                                            <>
                                                                <option key={0} value={0}>Select Driver</option>
                                                                {drivers.map(d => (
                                                                    <option key={d.driverId} value={d.driverId}>{d.driverName}</option>
                                                                ))}
                                                            </>
                                                        ) : (
                                                        <option key={0} value={0}>Loading...</option>
                                                    )}
                                        </select>
                                        <ErrorMessage name='driverId' component='span' className='form-input-row-error' />
                                    </div>
                                )}
                                {currentApp?.showExtras && (
                                    <div className="form-row">
                                    <label htmlFor='extras' className="form-input-label">{currentApp.extrasCaption}</label>
                                    <input type="string" placeholder={currentApp.extrasCaption} name="extras" value={licenseValues.extras} className="form-input-field" disabled={true} onChange={(event) => setFieldValue('extras',event.target.value)} />
                                    <FaSearch className="lookup-icon" size={36} onClick={()=>{setShowLookUp(true)}} />
                                    <ErrorMessage name='extras' component='span' className='form-input-row-error' />
                                </div>
                                )}
                                <div className="form-row">
                                    <label htmlFor='shopCode' className="form-input-label">Shop Code</label>
                                    <input type="string" placeholder="Shop code" name="shopCode" className="form-input-field" onChange={(event) => setFieldValue('shopCode',event.target.value)} />
                                    <ErrorMessage name='shopCode' component='span' className='form-input-row-error' />
                                </div>
                                <div className="form-row">
                                    <div className="form-input-group">
                                        <label htmlFor="validFrom" className="form-row-input-label">Valid From</label>
                                        <DatePicker selected={values.validFrom} dateFormat='dd/MM/yyyy' name="validFrom" className='form-input-field' onChange={date => setFieldValue('validFrom',date)} />
                                        <ErrorMessage name='validFrom' component='span' className='form-input-row-error' />
                                    </div>
                                    <div className="form-input-group">
                                        <label htmlFor="validTill" className="form-row-input-label">Valid Till</label>
                                        <DatePicker selected={values.validTill} dateFormat='dd/MM/yyyy' name="validTill" className='form-input-field' onChange={date => setFieldValue('validTill',date)} />
                                        <ErrorMessage name='validTill' component='span' className='form-input-row-error' />
                                    </div>
                                </div>
                                <div className="form-input-list">
                                    <h2 className="form-input-list-title">Modules</h2>
                                    {loadingModules ? (
                                        <span>Loading modules...</span>
                                    ) : (
                                        <ul>
                                        {modules.map((module,index) => (
                                            <li key={index}><input type='checkbox' value={module.id} name='modules' onChange={handleChange}  /><label>{module.moduleName}</label> </li>
                                        ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                            <div className="dialog-footer">
                                <button type='button' disabled={isSubmitting} className="btn btn-with-icon btn-cancel" onClick={hideDialog}>Cancel</button>
                                <button type='submit' disabled={isSubmitting} className="btn btn-with-icon btn-default">OK</button>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
    </Formik>
    {showLookUp && <LocationLookUp data={locations} showLookUp={showLookUp} hideLookUp={()=>setShowLookUp(false)} onSelect={handleOnLocationSelected} selectedValue={licenseValues.extras} />}
    </>
  )
}

export default observer(RequestProcessDialog);