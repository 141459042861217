import { ReactNode } from 'react'
import { FaTimesCircle,  } from 'react-icons/fa'

interface IProps{
    title: string;
    showDialog: boolean;
    onOK: () => void;
    onCancel: () => void;
    okDisabled: boolean;
    isSubmitting: boolean;
    okLabel?: string;
    cancelLabel?: string;
    hasScroll?: boolean;
    children?: ReactNode;
    showFooter?: boolean;
}
const DialogBox : React.FC<IProps> = ({
title,
showDialog,
onOK,
onCancel,
okDisabled,
isSubmitting,
okLabel = 'Save',
cancelLabel = 'Cancel',
hasScroll = false,
children,
showFooter = true  
}) => {
  return (
        <div className={`dialog-wrapper ${showDialog ? "dialog-show" : ""}`}>
        <div className='dialog-box'>
            <div className="dialog-title">
                <h2 data-alter-title>{title}</h2>
                <span className="dialog-close">
                    <FaTimesCircle onClick={onCancel} />
                </span>
            </div>
            <div className={`dialog-body  ${hasScroll ? "overflow-scroll" : ""}`}>
                {children && children}
            </div>
            {showFooter && (
            <div className="dialog-footer">
                <button type='button' disabled={isSubmitting} className="btn btn-with-icon btn-cancel" onClick={(e) => {e.preventDefault(); onCancel();}}>{cancelLabel}</button>
                <button type='submit' disabled={okDisabled} className="btn btn-with-icon btn-default" onClick={onOK}>{okLabel}</button>
            </div>
            )}
        </div>
    </div>
  )
}

export default DialogBox