import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Header, PrivateRoute } from "./components/layout";
import { ApplicationsPage, HomePage, DevicesPage, RequestsPage } from "./pages";
import { useStore } from "./stores/store";
import './css/styles.css';
import { observer } from "mobx-react-lite";
import { LoadingComponent } from "./components/common";
function App() {
  const {commonStore:{token, user,appLoading,setAppLoaded,getUser}} = useStore()
  useEffect(()=>{
    if(token && !user)
      getUser().then(() => setAppLoaded());
    else
      setAppLoaded();
  },[token,user,getUser,setAppLoaded])
  if(appLoading)
    return <LoadingComponent message="Loading app.." />

  return (
    <>
      <Header />
      <div className='container'>
          <div className={`main-contents ${!user && 'empty'}`}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route element={<PrivateRoute user={user ?? undefined} />}>
                <Route path="/applications" element={<ApplicationsPage />} />
                <Route path="/requests" element={<RequestsPage />} />
                <Route path="/devices" element={<DevicesPage />} />
                {/*<Route path="/changepassword" element={<ChangePasswordPage />} />*/}
              </Route>
            </Routes>
          </div>
      </div>
      <div className='footer'>
        <div className='footer-container'>
          <span>Copyright 2022, Axcess IT</span>
        </div>
      </div>
    </>
  );
}

export default observer(App);
