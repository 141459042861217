import { observer } from 'mobx-react-lite'
import React,{ useEffect } from 'react'
import { TbGitPullRequest } from 'react-icons/tb'
import { MdOutlineDevicesOther } from 'react-icons/md'
import { AiFillAppstore } from 'react-icons/ai'
import { useStore } from '../../stores/store'
import { LoadingComponent, Widget } from '../common'
import { TopDeviceGrid } from '../Devices'
import { TopApplicationsGrid } from '../Applications'
import TopRequestsGrid from '../Requests/TopRequestsGrid'

const Dashboard = () => {
    const {commonStore:{apiReturned,apiError,lastError}} = useStore()
  const {commonStore:{appLoading,setAppLoaded},devicesStore:{loadDevices},appsStore:{loadApps,loadAppDbs,loadAppModules},requestsStore:{loadRequests,loadReqTypes,loadReqStatuses}} = useStore();
  useEffect(()=>{
    setAppLoaded(false);
    loadApps();
    loadAppModules();
    loadAppDbs();
    loadDevices();
    loadRequests();
    loadReqStatuses();
    loadReqTypes();
    setAppLoaded(true);
  },[])

    if(appLoading)
        return <LoadingComponent message="Loading app.." />

    if(apiReturned && apiError)
        return <div>{lastError}</div>
    return (
        <>
            <Widget heading='Recent Requests' headingIcon={<TbGitPullRequest />}>
                <TopRequestsGrid />
            </Widget>
            <Widget heading='Top Devices' headingIcon={<MdOutlineDevicesOther />}>
                <TopDeviceGrid />
            </Widget>
            <Widget heading='Top Applications' headingIcon={<AiFillAppstore />}>
                <TopApplicationsGrid />
            </Widget>
        </>
  )
}

export default observer(Dashboard);