
import { IDeviceEntity } from "../../models"
import { ToDDMMYY, truncate } from "../../utils/Utils";
import { BsToggle2Off, BsToggle2On, BsToggles2 } from 'react-icons/bs';
import { VscRepoPush } from 'react-icons/vsc'
import { CgListTree } from 'react-icons/cg'
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { RequestsSubGrid } from "../Requests";

interface IProps{
    device: IDeviceEntity;
}

const DeviceGridRow : React.FC<IProps> = ({device}) => {
    const [showRequests,setShowRequests] = useState(false);
  return (
    <div className="data-grid-row">
        <div key={`${device.id}_1`} className='data-grid-col'>{device.mobileNo}</div>
        <div key={`${device.id}_2`} className='data-grid-col'>{device.appName}</div>
        <div key={`${device.id}_3`} className='data-grid-col'>{truncate(device.license)}</div>
        <div key={`${device.id}_4`} className='data-grid-col'>{device.issuedOn ? ToDDMMYY(new Date(device.issuedOn)) : "NA"}</div>
        <div key={`${device.id}_5`} className='data-grid-col'>{device.validTill ? ToDDMMYY(new Date(device.validTill)) : 'NA'}</div>
        <div key={`${device.id}_6`} className='data-grid-col'>{device.isEnabled ? <BsToggle2On className="icon-no-cursor icon-status-active" /> : <BsToggle2Off className="icon-no-cursor icon-status-inactive" />}</div>
        <div key={`${device.id}_9`} className='data-grid-col'><CgListTree onClick={()=>setShowRequests(!showRequests)} /></div>
        {showRequests && 
        <div className="data-subgrid-row">
            <RequestsSubGrid key={device.id} id={device.id} />
        </div>}
    </div>
  )
}

export default observer(DeviceGridRow);