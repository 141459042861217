import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { MdCancel } from 'react-icons/md';
import { IAppDatabase, IAppDatabaseEntity } from "../../models";
import { useStore } from "../../stores/store";
import { InputPrompt } from "../common";

interface IProps{
    id: string;
}

const AppDatabaseList : React.FC<IProps> = ({id}) => {
    const {commonStore:{apiCalled,apiError,lastError}, appsStore:{appDbs,loadAppDbs,getAppDbs,addDb,delDb}} = useStore();
    const [showAdd, setShowAdd] = useState(false);
    const [dbList,setDbList] = useState<IAppDatabaseEntity[]>([])
    useEffect(()=>{
        if(appDbs.length === 0)
            loadAppDbs();
    })

    useEffect(() => {
        if(appDbs.length > 0 && dbList.length === 0)
            setDbList(getAppDbs(id));
    },[appDbs.length,dbList.length,setDbList,getAppDbs,id])

    const handleAddNew = (dbName: string ) => {
        let db: IAppDatabase = {
          appId: id,
          databaseName: dbName
        };
        addDb(db) 
        setShowAdd(false);
    }
    const handleDelete = (dbid: number) => {
        delDb(dbid);
        setDbList(getAppDbs(id))
    }
    if(appDbs.length === 0 && !apiCalled)
        if(apiError)
            return <span>{lastError}</span>
    return (
    
    <div className="data-list-container">
         <div className="data-list-header">
            <h2 className="data-list-title">Databases</h2>
            <button type="button" className="btn btn-tiny" onClick={()=>setShowAdd(true)}>Add</button>
        </div>
        <ul>
        { dbList.length === 0 ? (
            <span>No Databases</span>
        ) : (
            <>
                {dbList.map((db) => (
                    <li key={db.id}><span>{db.databaseName}</span><MdCancel className="icon-status-inactive" onClick={() => handleDelete(db.id)} /></li>
                ))}
            </>
        )}
        </ul>
        {showAdd && <InputPrompt title="Add Database" label="Database Name" minLength={3} maxLength={50} onCancel={()=>setShowAdd(false)} onSave={handleAddNew} showPrompt={showAdd} value='' />}
    </div>
  )
}

export default observer(AppDatabaseList);