import { ErrorMessage, Form, Formik, FormikErrors } from "formik";
import * as Yup from 'yup';
import { ILoginValues } from "../../models";
import { MD5 } from 'crypto-js'
import { useStore } from "../../stores/store";
import { InputGroup } from ".";
import { observer } from "mobx-react-lite";
import { FaTimesCircle } from "react-icons/fa";

const LoginBox = () => {
const {commonStore:{login}} = useStore();
const initValues = () => ({username:'', password:'', error: null});
const validationSchema = Yup.object({
        username: Yup.string().required().min(5).max(50),
        password: Yup.string().required().min(5).max(25)
    });
const onSubmit = (values: ILoginValues, setErrors: (errors: FormikErrors<ILoginValues>) => void) =>{
        return login({
            username: values.username,
            passwordHash: MD5(values.password).toString().toUpperCase(),
        }).catch((err: string) => setErrors({error: err}));
    }
  return (
            <Formik
                initialValues={initValues()}
                onSubmit={(values,{setErrors}) => onSubmit(values,setErrors)}
                validationSchema={validationSchema}
            >
            {({handleSubmit, isSubmitting, dirty, touched, errors}) =>  (
                <div className='login-box'>
                    <div className="dialog-title">
                        <h2 data-alter-title>Log In</h2>
                        <span className="dialog-close">
                            <FaTimesCircle />
                        </span>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <div className='login-body'>
                                <InputGroup name='username' placeholder='Username' type='text' label="Username" disabled={isSubmitting && dirty && !!touched}/>
                                <InputGroup name='password' placeholder='Password' type='password' label="Password" disabled={isSubmitting && dirty && !!touched}/>
                                <ErrorMessage name='error' render={() => <span className='input-error'>{errors.error}</span>} />
                        </div>
                        <div className="dialog-footer">
                            <button type='submit' disabled={isSubmitting && dirty && !!touched} className="btn btn-with-icon btn-default" >Log In</button>
                        </div>
                    </Form>

                </div>
            )}
            </Formik>
  )
}

export default observer(LoginBox)