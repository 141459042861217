import { observer } from "mobx-react-lite"
import TopDeviceGridRow from "./TopDeviceGridRow"
import { useStore } from './../../stores/store';
import { BsSortDown, BsSortUpAlt } from "react-icons/bs";

const TopDeviceGrid = () => {
    const {devicesStore:{TopDevices,devSortColumn,devSortMode,setDevSortColumn}} = useStore();
  return (
     <>
        <div className='data-grid top-device-grid'>
            <div className='data-grid-row heading-row'>
                <div onClick={() => setDevSortColumn(1)} className='data-grid-col'><span>Mobile No</span>{devSortColumn === 1 && (devSortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div onClick={() => setDevSortColumn(2)} className='data-grid-col'><span>App Name</span>{devSortColumn === 2 && (devSortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div className='data-grid-col'>License</div>
                <div onClick={() => setDevSortColumn(3)} className='data-grid-col'><span>Issued On</span>{devSortColumn === 3 && (devSortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div onClick={() => setDevSortColumn(4)} className='data-grid-col'><span>Valid Till</span>{devSortColumn === 4 && (devSortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div className='data-grid-col'>Status</div>
                <div className='data-grid-col'>Requests</div>
            </div>
                {TopDevices.length === 0 ? (
                    <div className="data-grid-full-row">No records</div>
                ) : (
                    TopDevices.map((dev,i) => (
                        <TopDeviceGridRow key={i} device={dev}  />
                    ))
                )}
        </div>
    </>
  )
}

export default observer(TopDeviceGrid);