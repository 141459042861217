import { observer } from "mobx-react-lite"
import { IDeviceEntity } from "../../models"
import DeviceGridRow from "./DeviceGridRow"
import { useStore } from './../../stores/store';
import { BsSortDown, BsSortUpAlt } from "react-icons/bs";
import { AlertBox } from "../common";
import { useState } from "react";

interface IProps{
    devices: IDeviceEntity[]
}
const DeviceGrid : React.FC<IProps> = ({devices}) => {
    
    const {devicesStore:{devSortColumn,devSortMode,setDevSortColumn,pushLicense,enableDevice,disableDevice}} = useStore();
    const [showAlert, setShowAlert] = useState(false);
    const [currentDevice, setCurrentDevice] = useState<IDeviceEntity|undefined>();

    const handleStatus = (id: string) => {
    let dev = devices.find(a => a.id === id);
    if(dev){
      setCurrentDevice(dev);
      setShowAlert(true);
    }
  }

  const handleOnStatusChange = () => {
    if(currentDevice){
      if(currentDevice.isEnabled)
        disableDevice(currentDevice.id);
      else
        enableDevice(currentDevice.id);
    }
    setShowAlert(false);
  }

  const handleCancel = () => {
    setCurrentDevice(undefined);
    setShowAlert(false);
  }
  return (
     <>
        <div className='data-grid device-grid'>
            <div className='data-grid-row heading-row'>
                <div onClick={() => setDevSortColumn(1)} className='data-grid-col'><span>Mobile No</span>{devSortColumn === 1 && (devSortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div onClick={() => setDevSortColumn(2)} className='data-grid-col'><span>App Name</span>{devSortColumn === 2 && (devSortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div className='data-grid-col'>License</div>
                <div onClick={() => setDevSortColumn(3)} className='data-grid-col'><span>Issued On</span>{devSortColumn === 3 && (devSortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div onClick={() => setDevSortColumn(4)} className='data-grid-col'><span>Valid Till</span>{devSortColumn === 4 && (devSortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div className='data-grid-col'>Status</div>
                <div className='data-grid-col'>Enable\Disable</div>
                <div className='data-grid-col'>Push</div>
                <div className='data-grid-col'>Requests</div>
            </div>
            <div className='data-grid-row'>
                {devices.length === 0 ? (
                    <div className="data-grid-full-row">No records</div>
                ) : (
                    devices.map((dev,i) => (
                        <DeviceGridRow key={i} device={dev} handleStatus={handleStatus} handlePush={pushLicense} />
                    ))
                )}
            </div>
        </div>
        {showAlert && currentDevice && <AlertBox title="Confirm Activation" message={`Are you sure you want to set ${currentDevice.mobileNo} to ${currentDevice.isEnabled ? 'Disable' : 'Enable'} ?`} onOK={handleOnStatusChange} showAlert={showAlert} onCancel={handleCancel} showCancel={true} />}
    </>
  )
}

export default observer(DeviceGrid);