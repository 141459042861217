import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { RequestsGrid, RequestsSearchBox } from "../components/Requests";
import { IRequestSearchParams } from "../models";
import { useStore } from "../stores/store"

const RequestsPage = () => {
  const {requestsStore:{requestList,loadRequests,setSearchParams,setReqSortColumn},commonStore:{setPrevRoute}} = useStore();

  useEffect(()=>{
  setPrevRoute(window.location.pathname);
    if(requestList.length === 0)
      loadRequests();
    setReqSortColumn(6);
  },[])

  const handleSearch = (params: IRequestSearchParams) => {
      setSearchParams(params);
  }
  return (
    <>
      <h2 className="main-contents-heading">Requests</h2>
      <RequestsSearchBox handleSearch={handleSearch} />
      <RequestsGrid requests={requestList} />
    </>
  )
}

export default observer(RequestsPage);