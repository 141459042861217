
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { IAppModuleEntity, IAppsEntity, IApiResponseStatus, IAppDatabaseEntity, IApps, IAppModule, IAppDatabase } from "../models";
import { DriverEntity } from "../models/drivers";
import { store } from "./store";
import { LocationLookUpEntity } from "../models/locationLookUp";

export default class AppsStore {

  apps: IAppsEntity[] = [];
  appModules: IAppModuleEntity[] = [];
  appDbs: IAppDatabaseEntity[] = [];
  drivers: DriverEntity[] = [];
  locations: LocationLookUpEntity[] = [];

  loadingApps = false;
  loadingDbs = false;
  loadingModules = false;
  loadingDrivers = false;
  loadingLocations = false;
  appSearchTerm: string = '';
  appsList: IAppsEntity[] = [];
  appSortColumn = 1;
  appSortMode = true;


  constructor() {
    makeAutoObservable(this);
  }

  setLoadingApps = (loading: boolean = true) => {
    this.loadingApps = loading;
  }

  setLoadingDbs = (loading: boolean = true) => {
    this.loadingDbs = loading;
  }

  setLoadingModules = (loading: boolean = true) => {
    this.loadingModules = loading;
  }

  setLoadingDrivers = (loading: boolean = true) => {
    this.loadingDrivers = loading;
  }

  setLoadingLocations = (loading: boolean = true) => {
    this.loadingLocations = loading;
  }


  setAppSortColumn = (col: number) => {
    if(this.appSortColumn === col)
      runInAction(() => this.appSortMode = !this.appSortMode);
    else
      runInAction(() => {
        this.appSortColumn = col
        this.appSortMode = true;
      })
    this.sortApps();
  }
  
  setSearchTerm = (search: string) => {
    this.appSearchTerm = search;
    this.sortApps();
  }

  sortApps = () => {
    if(this.apps.length === 0)
      this.appsList = [];
    else{
      let applst = this.apps;
      if(this.appSearchTerm.length > 0)
        applst = applst.filter(r => r.appName.includes(this.appSearchTerm));
      switch(this.appSortColumn){
         case 1:
             if(this.appSortMode)
               this.appsList = applst.sort((a,b) => a.appName > b.appName ? 1 : -1);
             else
                this.appsList = applst.sort((a,b) => a.appName > b.appName ? -1 : 1);
              break;
         case 2:
             if(this.appSortMode)
               this.appsList = applst.sort((a,b) => a.endPoint > b.endPoint ? 1 : -1);
             else
                this.appsList = applst.sort((a,b) => a.endPoint > b.endPoint ? -1 : 1);
              break;
         case 3:
             if(this.appSortMode)
               this.appsList = applst.sort((a,b) => a.passcode > b.passcode ? 1 : -1);
             else
                this.appsList = applst.sort((a,b) => a.passcode > b.passcode ? -1 : 1);
              break;
        default:
          this.appsList = applst;
          break;
       };
    }
  }




  loadApps = async () => {
    this.setLoadingApps();
    let response = await agent.Apps.all()
    if(response.status === IApiResponseStatus.ResponseOK){
      runInAction(() => this.apps = response.result);
      this.sortApps();
    }
    else
      store.commonStore.setLastError(response.error.description);
    this.setLoadingApps(false);
  }

  get TopApps()  {
    if(this.apps.length === 0)
      return [];
    switch(this.appSortColumn){
         case 1:
             if(this.appSortMode)
               return this.apps.slice(0,5).sort((a,b) => a.appName > b.appName ? 1 : -1);
             else
               return this.apps.slice(0,5).sort((a,b) => a.appName > b.appName ? -1 : 1);
         case 2:
             if(this.appSortMode)
               return this.apps.slice(0,5).sort((a,b) => a.endPoint > b.endPoint ? 1 : -1);
             else
              return  this.apps.slice(0,5).sort((a,b) => a.endPoint > b.endPoint ? -1 : 1);
         case 3:
             if(this.appSortMode)
                return this.apps.slice(0,5).sort((a,b) => a.passcode > b.passcode ? 1 : -1);
             else
                return this.apps.slice(0,5).sort((a,b) => a.passcode > b.passcode ? -1 : 1);
        default:
          return this.apps.slice(0,5);
       };
  }

  getApp = (id: string) => {
    return this.apps.find(a => a.id === id);
  }
  

  addNew = async (app: IApps) => {
    store.commonStore.setApiCalled();
    let response = await agent.Apps.new(app) 
    if(response.status === IApiResponseStatus.ResponseOK){
      let newApp: IAppsEntity = {
        id: response.result,
        appName: app.appName,
        endPoint: app.endPoint,
        passcode: app.passcode,
        isActive: true,
        showDriver: app.showDriver,
        showExtras: app.showExtras,
        extrasCaption: app.extrasCaption,
        updateTerminal: app.updateTerminal,
        terminalType: app.terminalType,
        fcsid: app.fcsid,
        fcsvk: app.fcsvk
      };
      runInAction(() => this.apps = [...this.apps,newApp])
      this.sortApps();
    } else 
        store.commonStore.setLastError(response.error.description);
    store.commonStore.setApiCalled(false);
  }

  update = async (id: string, app: IApps) => {
    store.commonStore.setApiCalled();
    let response = await agent.Apps.update(id, app) 
    if(response.status === IApiResponseStatus.ResponseOK){
      let newApp: IAppsEntity = {
        id: id,
        appName: app.appName,
        endPoint: app.endPoint,
        passcode: app.passcode,
        isActive: true,
        showDriver: app.showDriver,
        showExtras: app.showExtras,
        extrasCaption: app.extrasCaption,
        updateTerminal: app.updateTerminal,
        terminalType: app.terminalType,
        fcsid: app.fcsid,
        fcsvk: app.fcsvk
      };
      runInAction(() => this.apps = [...this.apps.filter(a => a.id !== id),newApp])
      this.sortApps();
    } else 
      store.commonStore.setLastError(response.error.description);
    store.commonStore.setApiCalled(false);
  }

  activate = async (id:string) => {
    store.commonStore.setApiCalled();
    let response = await agent.Apps.activate(id);
    if(response.status === IApiResponseStatus.ResponseOK){
      runInAction(() => {
          let activated = this.apps.find(a => a.id === id);
          if(activated){
            activated.isActive = true;
            this.apps = [...this.apps.filter(a => a.id !== id),activated]
          }
      })
      this.sortApps();
    }
    else
      store.commonStore.setLastError(response.error.description);
    store.commonStore.setApiCalled(false);
  }

  deactivate = async (id:string) => {
    store.commonStore.setApiCalled();
    let response = await agent.Apps.deactivate(id) 
    if(response.status === IApiResponseStatus.ResponseOK){
      runInAction(() => {
          let activated = this.apps.find(a => a.id === id);
          if(activated){
            activated.isActive = false;
            this.apps = [...this.apps.filter(a => a.id !== id),activated]
          }
      })
      this.sortApps();
    }else
          store.commonStore.setLastError(response.error.description);
    store.commonStore.setApiCalled(false);
  }

  loadAppModules = async () => {
    this.setLoadingModules();
    let response = await agent.AppModules.all()
    if(response.status === IApiResponseStatus.ResponseOK)
      runInAction(() => this.appModules = response.result);
    else
      store.commonStore.setLastError(response.error.description);
    this.setLoadingModules(false);
  }

  getAppModules = (id: string) => {
    if(this.appModules.length === 0)
      return [];
    return this.appModules.filter(m => m.appId === id);
  }

  addModule = async (module: IAppModule) => {
    store.commonStore.setApiCalled();
    let response = await agent.AppModules.new(module) 
    if(response.status === IApiResponseStatus.ResponseOK)
      runInAction(() => this.appModules = [...this.appModules,response.result]);
    else
      store.commonStore.setLastError(response.error.description);
    store.commonStore.setApiCalled(false);
  }

  updateModule = async (id:number,module:IAppModule) => {
    store.commonStore.setApiCalled();
    let response = await agent.AppModules.update(id,module) 
    if(response.status === IApiResponseStatus.ResponseOK){
      let appModule:IAppModuleEntity = {
        id: id,
        appId: module.appId,
        moduleName: module.moduleName,
        isEnabled: true
      };
      runInAction(() => this.appModules = [...this.appModules.filter(m => m.id !== id),appModule]);
    }
    else
      store.commonStore.setLastError(response.error.description);
    store.commonStore.setApiCalled(false);
  }

  enableModule = async (id: number) => {
    store.commonStore.setApiCalled();
    let response = await agent.AppModules.enable(id);
    if(response.status === IApiResponseStatus.ResponseOK){
      let mod = this.appModules.find(m => m.id === id)
      if(mod){
        mod.isEnabled = true;
        runInAction(() => this.appModules = this.appModules = [...this.appModules.filter(m => m.id !== id),mod!]);
      }
    }
    else
      store.commonStore.setLastError(response.error.description);
    store.commonStore.setApiCalled(false);
  }

  disableModule = async (id: number) => {
    store.commonStore.setApiCalled();
    let response = await agent.AppModules.disable(id);
    if(response.status === IApiResponseStatus.ResponseOK){
      let mod = this.appModules.find(m => m.id === id)
      if(mod){
        mod.isEnabled = false;
        runInAction(() => this.appModules = this.appModules = [...this.appModules.filter(m => m.id !== id),mod!]);
      }
    } 
    else
      store.commonStore.setLastError(response.error.description);
    store.commonStore.setApiCalled(false);
  }

  loadAppDbs = async () => {
    this.setLoadingDbs();
    let response = await agent.AppDb.all()
    if(response.status === IApiResponseStatus.ResponseOK)
      runInAction(() => this.appDbs = response.result);
    else
      store.commonStore.setLastError(response.error.description);
    this.setLoadingDbs(false);
  }
  
  getAppDbs = (id: string) => {
    if(this.appDbs.length === 0)
      return [];
    return this.appDbs.filter(db => db.appId === id);
  }

  addDb = async (db: IAppDatabase) => {
    store.commonStore.setApiCalled();
    let response = await agent.AppDb.new(db) 
    if(response.status === IApiResponseStatus.ResponseOK)
      runInAction(() => this.appDbs = [...this.appDbs,response.result]);
    else
      store.commonStore.setLastError(response.error.description);
    store.commonStore.setApiCalled(false);
  }
  
  updateDb = async (id:number, db: IAppDatabase) => {
    store.commonStore.setApiCalled();
    let response = await agent.AppDb.update(id,db);
    if(response.status === IApiResponseStatus.ResponseOK){
      let appDb:IAppDatabaseEntity = {
        appId: db.appId,
        databaseName: db.databaseName,
        id: id,
        isDefault : false
      };
      runInAction(() => this.appDbs = [...this.appDbs.filter(db => db.id !== id),appDb])
    }
    else
      store.commonStore.setLastError(response.error.description);
    store.commonStore.setApiCalled(false);
  }

  delDb = async (id:number) => {
    store.commonStore.setApiCalled();
    let response = await agent.AppDb.delete(id) 
    if(response.status === IApiResponseStatus.ResponseOK)
      runInAction(() => this.appDbs = [...this.appDbs.filter(db => db.id !== id)])
    else
      store.commonStore.setLastError(response.error.description);
    store.commonStore.setApiCalled(false);
  }
  getDrivers = async (db: string) => {
    this.setLoadingDrivers();
    let response = await agent.Apps.getdrivers(db)
    if(response.status === IApiResponseStatus.ResponseOK)
      runInAction(() => this.drivers = response.result);
    else
      runInAction(() => this.drivers = []);
    this.setLoadingDrivers(false);
  }

  resetDrivers = () =>{
    this.drivers.length = 0;
  }

  getLocations = async (db: string) => {
    this.setLoadingLocations();
    let response = await agent.Apps.getLocations(db)
    if(response.status === IApiResponseStatus.ResponseOK)
      runInAction(() => this.locations = response.result);
    else
      runInAction(() => this.locations = []);
    this.setLoadingLocations(false);
  }

  resetLocations = () =>{
    this.locations.length = 0;
  }

}