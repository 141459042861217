import { format } from "date-fns";

export const ToDDMMYY = (date: Date) => {
    return format(date,'dd/LL/yyyy');
}
export const ToDateTime = (date: Date) => {
    return format(date,'dd/MM/yy HH:mm:ss');
}
export const addYear = (date: Date) => {
    return new Date(date.getFullYear() + 1, date.getMonth(), date.getDate());
}

export const truncate = (value?: string, max: number = 50) => {
    if(value){
        return (value.length > max) ? value.slice(0, max-1) + '\u2026' : value;
    }
    else
        return 'N/A';
}