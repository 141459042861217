import { useState } from "react";

interface IProps{
    showPrompt: boolean;
    title: string;
    label: string;
    value: string;
    onSave: (value: string) => void;
    onCancel: () => void;
    minLength: number;
    maxLength: number;
}
const InputPrompt : React.FC<IProps> = ({
    showPrompt,title,label,value,minLength,maxLength,onSave,onCancel
}) => {
    const [localValue,setLocalValue] = useState(value);
    const [error, setError] = useState('');

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = event.target.value;
            setLocalValue(newValue)
        if (newValue.length < minLength)
            setError(`Must be atleast ${minLength} characters`);
        else if(newValue.length > maxLength)
            setError(`Cannot be more than ${maxLength} characters`);
        else{
            setError('');
        }
    }

  return (
        <div className={`dialog-wrapper ${showPrompt ? 'dialog-show' : ''}`}>
        <div className='dialog-box'>
            <div className="dialog-title">
                <h2 data-alter-title>{title}</h2>
            </div>
            <div className='dialog-body'>
                <div className="form-input-group">
                    <label htmlFor='localValue' className="form-input-label">{label}</label>
                    <input type='text' className="form-input-field" value={localValue} onChange={handleOnChange} />
                    <span className="form-input-error">{error}</span>        
                </div>
            </div>
            <div className="dialog-footer">
                <button type='button' className="btn btn-cancel" onClick={onCancel}>Cancel</button>                
                <button type='button' disabled={error.length > 0} className="btn btn-default" onClick={() => onSave(localValue)}>Save</button>
            </div>
        </div>
    </div>
  )
}

export default InputPrompt