import { ReactNode } from "react";

interface IProps{
    small?: boolean;
    noBorder?: boolean;
    heading?: string;
    headingIcon?: ReactNode,
    children?: ReactNode;
    footer?: ReactNode;
}
const Widget: React.FC<IProps> = ({small,noBorder,heading,headingIcon,children,footer}) => {
  return (
        <div className={`widget ${small ? 'widget-small' : ''} ${noBorder?'widget-no-border':''}`}>
            <div className="widget-header">
                    <h2>{headingIcon && headingIcon}{heading}</h2>
                </div>
                <div className='widget-body'>
                    {children && children}
                </div>
                {footer && (
                    <div className='widget-footer'>
                    {footer}
                    </div>
                )}
            </div>
        )
}

export default Widget