import { ErrorMessage, Field, Form, Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import { IAppsEntity } from '../../models';
import { DialogBox } from '../common'
import * as Yup from 'yup';

interface IProps{
    app?: IAppsEntity;
    showDialog: boolean;
    onSave: (app: IAppsEntity) => void;
    onCancel: () => void;
}

const AppDialog : React.FC<IProps> = ({app,showDialog,onSave,onCancel}) => {

    const validationSchema = Yup.object({
          appName: Yup.string().required('Kindly provide application name').min(3,'Name must be alteast 3 characters').max(50, 'Maximum 50 characters allowed'),
          endPoint: Yup.string().url().required('Kindly provide endpoint').min(10,'Endpoint must be alteast 10 characters').max(100, 'Maximum 50 characters allowed'),
          passcode: Yup.string().required('Kindly provide a passcode').min(10,'Passcode must be atleast 10 characters').max(25, 'Maximum 25 characters allowed'),
          showDriver: Yup.boolean().required(),
          showExtras: Yup.boolean().required(),
          extrasCaption: Yup.string().max(50, "Extras caption cannot be more than 50 characters"),
          updateTerminal: Yup.boolean().required(),
          terminalType: Yup.string(),
          fcsid: Yup.string().required('Kindly provide FCM sender id'),
          fcsvk: Yup.string().required('Kindly provide FCM key')
    });
    const handleSave = (values: IAppsEntity) => {
      onSave(values);
    }
  return (
    <Formik 
        initialValues={{
                        id: app?.id ?? '',
                        appName: app?.appName ?? '',
                        endPoint: app?.endPoint ?? '',
                        passcode: app?.passcode ?? '',
                        showDriver: app?.showDriver ?? false,
                        showExtras: app?.showExtras ?? false,
                        extrasCaption: app?.extrasCaption ?? '',
                        updateTerminal: app?.updateTerminal ?? false,
                        terminalType: app?.terminalType ?? '',
                        isActive: app?.isActive ?? true,
                        fcsid: app?.fcsid ?? '',
                        fcsvk: app?.fcsvk ?? ''
                        }} 
        validationSchema={validationSchema}
        onSubmit={(values) => handleSave(values)}
        enableReinitialize={true}>
      {({handleSubmit, isSubmitting,values}) =>  (
        <Form onSubmit={handleSubmit}>
          <DialogBox title={app ? `Edit ${app.appName}` : 'Add New Application'} showDialog={showDialog} onOK={handleSubmit} onCancel={onCancel} okDisabled={isSubmitting} isSubmitting={isSubmitting}>
            <div className="form-input-row">
              <label htmlFor='appName' className="form-input-row-label">App Name</label>
              <Field name='appName'className='form-input-row-field' type='text' />
              <ErrorMessage name='appName' component='span' className='form-input-row-error' />
            </div>
            <div className="form-input-row">
              <label htmlFor='endPoint' className="form-input-label">Endpoint</label>
              <Field name='endPoint'className='form-input-row-field' type='url' />
              <ErrorMessage name='endPoint' component='span' className='form-input-row-error' />
            </div>
            <div className="form-input-row">
              <label htmlFor='passcode' className="form-input-label">Passcode</label>
              <Field name='passcode'className='form-input-row-field' type='url' />
              <ErrorMessage name='passcode' component='span' className='form-input-row-error' />
            </div>
            <div className="form-input-row">
              <label htmlFor='showDriver' className="form-input-label">Show Driver</label>
              <Field name='showDriver'className='form-input-row-check' type='checkbox' />
              <ErrorMessage name='showDriver' component='span' className='form-input-row-error' />
            </div>
            <div className="form-input-row">
              <label htmlFor='showExtras' className="form-input-label">Show Extras</label>
              <Field name='showExtras'className='form-input-row-check' type='checkbox' />
              <ErrorMessage name='showExtras' component='span' className='form-input-row-error' />
            </div>
            {values.showExtras && (
              <div className="form-input-row">
                <label htmlFor='extrasCaption' className="form-input-row-label">Extras Caption</label>
                <Field name='extrasCaption'className='form-input-row-field' type='text' />
                <ErrorMessage name='extrasCaption' component='span' className='form-input-row-error' />
              </div>
            )}
            <div className="form-input-row">
              <label htmlFor='updateTerminal' className="form-input-label">Update Terminal Table</label>
              <Field name='updateTerminal'className='form-input-row-check' type='checkbox' />
              <ErrorMessage name='updateTerminal' component='span' className='form-input-row-error' />
            </div>
            <div className="form-input-row">
              <label htmlFor='terminalType' className="form-input-row-label">TRWSType</label>
              <Field name='terminalType'className='form-input-row-field' type='text' />
              <ErrorMessage name='terminalType' component='span' className='form-input-row-error' />
            </div>
            <div className="form-input-row">
              <label htmlFor='fcsid' className="form-input-row-label">FCM Sender ID</label>
              <Field name='fcsid'className='form-input-row-field' type='text' />
              <ErrorMessage name='fcsid' component='span' className='form-input-row-error' />
            </div>
            <div className="form-input-row">
              <label htmlFor='fcsvk' className="form-input-row-label">FCM Key</label>
              <Field name='fcsvk'className='form-input-row-field' type='text' />
              <ErrorMessage name='fcsvk' component='span' className='form-input-row-error' />
            </div>
          </DialogBox>
        </Form>
      )}
    </Formik>
  )
}

export default observer(AppDialog);