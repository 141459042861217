export interface IApiResponse{
  status: IApiResponseStatus;
  error: IApiResponseError;
}

export interface IApiResponseT<T>{
  status: IApiResponseStatus;
  error: IApiResponseError;
  result: T;
}

export interface IApiResponseError{
  code: number;
  description: string;
}

export enum IApiResponseStatus {
    ResponseError,
    ResponseOK
}