import { IRequestItem } from "../../models";
import { FaKey } from 'react-icons/fa'
import { VscRepoPush } from 'react-icons/vsc';
import { ToDateTime } from "../../utils/Utils";
import { observer } from "mobx-react-lite";


interface IProps{
    request: IRequestItem;
    handleIssue: (id: string) => void;
    handlePush: (id: string) => void;
}

const RequestsGridRow : React.FC<IProps> = ({request, handleIssue,handlePush}) => {
  return (
    <>
        <div key={`${request.id}_1`} className='data-grid-col'>{ToDateTime(new Date(request.createdOn))}</div>
        <div key={`${request.id}_2`} className='data-grid-col'>{request.mobileNo}</div>
        <div key={`${request.id}_3`} className='data-grid-col'>{request.appName}</div>
        <div key={`${request.id}_4`} className='data-grid-col'>{request.type}</div>
        <div key={`${request.id}_5`} className='data-grid-col'>{request.status}</div>
        <div key={`${request.id}_6`} className='data-grid-col'>{request.processedOn ? ToDateTime(new Date(request.processedOn)) : 'Not Processed'}</div>
        <div key={`${request.id}_7`} className={`data-grid-col ${request.status === 'Received' ? '' : 'icon-disabled'}`}><FaKey onClick={() => handleIssue(request.id)}/></div>
        <div key={`${request.id}_8`} className={`data-grid-col ${request.license ? '' : 'icon-disabled' }`}><VscRepoPush onClick={() => handlePush(request.id)}/></div>
    </>
  )
}

export default observer(RequestsGridRow)