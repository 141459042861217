
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { IApiResponseStatus, IRequestEntity, IRequestItem, IRequestSearchParams, kvp } from "../models";
import { store } from "./store";

export default class RequestsStore {

  reqTypes: kvp<number,string>[] = [];
  reqStatuses: kvp<number,string>[] = [];
  requests: IRequestItem[] = [];
  
  requestList: IRequestItem[] = [];
  searchParam: IRequestSearchParams = {appName: '',mobileNo: '',showCancelled: false, showProcessed: false};
  reqSortColumn = 6;
  reqSortMode = true;

  currentRequest: IRequestEntity | undefined;
  showReqProcessDlg = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadReqTypes = async () => {
    store.commonStore.setApiCalled();
    let response = await agent.Requests.getTypes();
              if(response.status === IApiResponseStatus.ResponseOK)
                this.reqTypes = response.result;
              else
                store.commonStore.setLastError(response.error.description);
    store.commonStore.setApiCalled(false);
  }


  setShowReqProcessDlg = (show: boolean) => {
    this.showReqProcessDlg = show;
  }

  loadReqStatuses = async () => {
    store.commonStore.setApiCalled();
    let response = await agent.Requests.getStatuses();
      if(response.status === IApiResponseStatus.ResponseOK)
        this.reqStatuses = response.result;
      else
        store.commonStore.setLastError(response.error.description);
    store.commonStore.setApiCalled(false);
  }

  loadRequests = async () => {
    store.commonStore.setApiCalled();
    let response = await agent.Requests.all();
    if(response.status === IApiResponseStatus.ResponseOK){
      runInAction(()=>this.requests = response.result);
      this.sortRequest();
    }
    else
      store.commonStore.setLastError(response.error.description);
    store.commonStore.setApiCalled(false);
  }

  sortRequest = () => {
    if (this.requests.length === 0)
      this.requestList = [];
    else{
      let reqs: IRequestItem[] = this.requests;
      if(this.searchParam.mobileNo.length > 0)
        reqs = reqs.filter(r => r.mobileNo.includes(this.searchParam.mobileNo));
      if(this.searchParam.appName.length > 0)
        reqs = reqs.filter(r => r.appName.toLowerCase().includes(this.searchParam.appName.toLowerCase()));
      if(!this.searchParam.showProcessed)
        reqs = reqs.filter(r => r.status !== 'Processed');
      if(!this.searchParam.showCancelled)
        reqs = reqs.filter(r => r.status !== 'Cancelled');
      switch(this.reqSortColumn){
        case 1:
            if(this.reqSortMode)
              runInAction(() => this.requestList = reqs.sort((a,b) => a.createdOn > b.createdOn ? 1 : -1));
            else
              runInAction(() => this.requestList = reqs.sort((a,b) => a.createdOn > b.createdOn ? -1 : 1));
            break;
        case 2:
            if(this.reqSortMode)
              runInAction(() => this.requestList = reqs.sort((a,b) => a.mobileNo > b.mobileNo ? 1 : -1));
            else
              runInAction(() => this.requestList = reqs.sort((a,b) => a.mobileNo > b.mobileNo ? -1 : 1));
            break;
        case 3:
            if(this.reqSortMode)
              runInAction(() => this.requestList = reqs.sort((a,b) => a.appName > b.appName ? 1 : -1));
            else
              runInAction(() => this.requestList = reqs.sort((a,b) => a.appName > b.appName ? -1 : 1));
            break;
        case 4:
            if(this.reqSortMode)
              runInAction(() => this.requestList = reqs.sort((a,b) => a.type > b.type ? 1 : -1));
            else
              runInAction(() => this.requestList = reqs.sort((a,b) => a.type > b.type ? -1 : 1));
            break;
        case 5:
            if(this.reqSortMode)
              runInAction(() => this.requestList = reqs.sort((a,b) => a.status > b.status ? 1 : -1));
            else
              runInAction(() => this.requestList = reqs.sort((a,b) => a.status > b.status ? -1 : 1));
            break;
        case 6:
            if(this.reqSortMode)
              runInAction(() => this.requestList = reqs.sort((a,b) => a.processedOn && b.processedOn && a.processedOn > b.processedOn ? 1 : -1));
            else
              runInAction(() => this.requestList = reqs.sort((a,b) => a.processedOn && b.processedOn && a.processedOn > b.processedOn ? -1 : 1));
            break;
          }
    }
  }
  setSearchParams = (searchParams:IRequestSearchParams) => {
    this.searchParam = searchParams;
    this.sortRequest();
  }

  setReqSortColumn = (col: number) => {
    if(this.reqSortColumn === col)
      runInAction(() => this.reqSortMode = !this.reqSortMode);
    else
      runInAction(() => {
        this.reqSortColumn = col
        this.reqSortMode = true;
      })
    this.sortRequest();
  }

  get TopRequests() {
    if(this.requests.length === 0)
      return [];
    switch(this.reqSortColumn){
        case 1:
            if(this.reqSortMode)
              return this.requests.filter(r => (r.status !== 'Cancelled')).slice(0,5).sort((a,b) => a.createdOn > b.createdOn ? 1 : -1);
            else
              return this.requests.filter(r => (r.status !== 'Cancelled')).slice(0,5).sort((a,b) => a.createdOn > b.createdOn ? -1 : 1);
        case 2:
            if(this.reqSortMode)
              return this.requests.filter(r => (r.status !== 'Cancelled')).slice(0,5).sort((a,b) => a.mobileNo > b.mobileNo ? 1 : -1);
            else
              return this.requests.filter(r => (r.status !== 'Cancelled')).slice(0,5).sort((a,b) => a.mobileNo > b.mobileNo ? -1 : 1);
        case 3:
            if(this.reqSortMode)
              return this.requests.filter(r => (r.status !== 'Cancelled')).slice(0,5).sort((a,b) => a.appName > b.appName ? 1 : -1);
            else
              return this.requests.filter(r => (r.status !== 'Cancelled')).slice(0,5).sort((a,b) => a.appName > b.appName ? -1 : 1);
        case 4:
            if(this.reqSortMode)
              return this.requests.filter(r => (r.status !== 'Cancelled')).slice(0,5).sort((a,b) => a.type > b.type ? 1 : -1);
            else
              return this.requests.filter(r => (r.status !== 'Cancelled')).slice(0,5).sort((a,b) => a.type > b.type ? -1 : 1);
        case 5:
            if(this.reqSortMode)
              return this.requests.filter(r => (r.status !== 'Cancelled')).slice(0,5).sort((a,b) => a.status > b.status ? 1 : -1);
            else
              return this.requests.filter(r => (r.status !== 'Cancelled')).slice(0,5).sort((a,b) => a.status > b.status ? -1 : 1);
        case 6:
            if(this.reqSortMode)
              return this.requests.filter(r => (r.status !== 'Cancelled')).slice(0,5).sort((a,b) => a.processedOn && b.processedOn && a.processedOn > b.processedOn ? 1 : -1);
            else
              return this.requests.filter(r => (r.status !== 'Cancelled')).slice(0,5).sort((a,b) => a.processedOn && b.processedOn && a.processedOn > b.processedOn ? -1 : 1);
        default:
              return this.requests.filter(r => (r.status !== 'Cancelled')).slice(0,5);
      }
  }

  getDeviceRequests = (id: string) => {
    if(this.requests.length === 0)
      return [];
    return this.requests.filter(r => r.deviceId === id);
  }
  getRequest = (id: string) => {
    return this.requests.find(r => r.id === id);
  }

  updateLicenseKey = (id: string, license: string): string => {
    let req = this.requests.find(r => r.id === id)
    let deviceId = '';
    if(req){
      deviceId = req.deviceId!;
      req.license = license;
      req.status = 'Processed';
      req.processedOn = new Date();
      runInAction(() => this.requests = [...this.requests.filter(r => r.id !== id),req!])
    }
    return deviceId;
  }
  updateReq = async (id: string, statusId: number) => {
    store.commonStore.setApiCalled();
    let req = this.requests.find(r => r.id === id);
    if(req){
      let status = this.reqStatuses.find(s => s.key === statusId);
      if(status){
      let response = await agent.Requests.update(id,statusId);
      if(response.status === IApiResponseStatus.ResponseError)
        store.commonStore.setLastError(response.error.description);
      else{
        req.status = status.value;
        runInAction(() => this.requests = [...this.requests.filter(r => r.id === id),req!])
        this.sortRequest();
      }
    }
    }
    store.commonStore.setApiCalled(false);
  }

  addReqType = async (type: kvp<number,string>) => {
    store.commonStore.setApiCalled();
    let response = await agent.Requests.addtype(type);
    if(response.status === IApiResponseStatus.ResponseOK)
      runInAction(() => this.reqTypes = [...this.reqTypes,type])
    else
      store.commonStore.setLastError(response.error.description);
    store.commonStore.setApiCalled(false);
  }

  delReqType = async (id: number) => {
    store.commonStore.setApiCalled();
    let response = await agent.Requests.deltype(id);
    if(response.status === IApiResponseStatus.ResponseOK)
      runInAction(() => this.reqTypes = [...this.reqTypes.filter(t => t.key !== id)])
    else
      store.commonStore.setLastError(response.error.description);
    store.commonStore.setApiCalled(false);
  }

  addReqStatus = async (status: kvp<number,string>) => {
    store.commonStore.setApiCalled();
    let response = await agent.Requests.addstatus(status);
    if(response.status === IApiResponseStatus.ResponseOK)
      runInAction(()=>this.reqStatuses = [...this.reqStatuses, status]);
    else
      store.commonStore.setLastError(response.error.description);
    store.commonStore.setApiCalled(false);
  }

  delReqStatus = async (id: number) => {
    store.commonStore.setApiCalled();
    let response = await agent.Requests.delstatus(id) 
    if(response.status === IApiResponseStatus.ResponseOK)
      runInAction(()=>this.reqStatuses = [...this.reqStatuses.filter(r => r.key !== id)]);
    else
      store.commonStore.setLastError(response.error.description);
    store.commonStore.setApiCalled(false);
  }
}