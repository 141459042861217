import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { ApplicationGrid, AppDialog } from "../components/Applications"
import { AlertBox, CrudHeader } from "../components/common"
import { IAppsEntity } from "../models"
import { useStore } from "../stores/store"

const ApplicationsPage = () => {

  const {appsStore:{appsList,loadApps,setSearchTerm,setAppSortColumn,addNew,update,activate,deactivate},commonStore:{setPrevRoute}} = useStore()
  const [showDialog, setShowDialog] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [currentApp, setCurrentApp] = useState<IAppsEntity|undefined>();

  useEffect(()=>{
    setPrevRoute(window.location.pathname);
    if(appsList.length === 0)
      loadApps();
    setAppSortColumn(1);
  },[])

  const handleStatus = (id: string) => {
    let statusApp = appsList.find(a => a.id === id);
    if(statusApp){
      setCurrentApp(statusApp);
      setShowAlert(true);
    }
  }

  const handleOnStatusChange = () => {
    if(currentApp){
      if(currentApp.isActive)
        deactivate(currentApp.id);
      else
        activate(currentApp.id);
    }
    setShowAlert(false);
  }

  const handleSearch = (search: string) => {
      setSearchTerm(search);
  }
  
  const handleNew = () => {
    setCurrentApp(undefined);
    setShowDialog(true);
  }

  const handleEdit = (app: IAppsEntity) => {
    setCurrentApp(app);
    setShowDialog(true);
  }

  const handleSave = (app: IAppsEntity) => {
    if(currentApp)
      update(currentApp.id, app);
    else
      addNew(app);
      setShowDialog(false);
  }
  const handleCancel = () => {
        setCurrentApp(undefined);
        setShowDialog(false);
        setShowAlert(false);
  }

  return (
    <>
      <h2 className="main-contents-heading">Applications</h2>
      <CrudHeader handleNew={handleNew} handleSearch={handleSearch} placeholder='App Name' />
      <ApplicationGrid apps={appsList} handleStatus={handleStatus} handleEdit={handleEdit} />
      {showDialog && <AppDialog app={currentApp} showDialog={showDialog} onSave={handleSave} onCancel={handleCancel} />}
      {showAlert && currentApp && <AlertBox title="Confirm Activation" message={`Are you sure you want to set ${currentApp.appName} to ${currentApp.isActive ? 'Inactive' : 'Active'} ?`} onOK={handleOnStatusChange} showAlert={showAlert} onCancel={handleCancel} showCancel={true} />}
    </>
  )
}

export default observer(ApplicationsPage)