import React from 'react'
import LookUpDialog from '../common/LookUpDialog'
import { LocationLookUpEntity } from '../../models/locationLookUp';
interface Props{
    data: LocationLookUpEntity[];
    showLookUp: boolean;
    hideLookUp: () => void;
    onSelect: (value: string) => void;
    selectedValue: string;
}
const LocationLookUp = ({data,showLookUp,hideLookUp,onSelect,selectedValue}:Props) => {
  return (
    <LookUpDialog
        title='Select Location'
        showDialog={showLookUp}
        onClose={hideLookUp}
    >
        <div className='data-grid location-lookup-grid'>
            <div className='lookup-grid-row'>
                <div className='data-grid-col'>Group</div>
                <div className='data-grid-col'>Location</div>
                <div className='data-grid-col'>Location Code</div>
                <div className='data-grid-col'>Shop No</div>
                <div className='data-grid-col'>Location Unique</div>
            </div>
            
                {data.length === 0 ? (
                <div className='lookup-grid-row'>
                    <div className="data-grid-full-row">No records</div>
                </div>
                ) : (
                    data.map((loc) => 
                    <div key={`${loc.shopNo}_${loc.locationUID}`} className={`lookup-grid-row ${selectedValue === loc.locationUID ? 'selected' : ''}`} onClick={()=>onSelect(loc.locationUID)}>
                        <div className='data-grid-col'>{loc.group}</div>
                        <div className='data-grid-col'>{loc.location}</div>
                        <div className='data-grid-col'>{loc.locationCode}</div>
                        <div className='data-grid-col'>{loc.shopNo}</div>
                        <div className='data-grid-col'>{loc.locationUID}</div>
                    </div>)
                )}
        </div>
    </LookUpDialog>
  )
}

export default LocationLookUp