import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { IRequestItem } from "../../models";
import { useStore } from "../../stores/store";
import RequestsSubGridRow from "./RequestsSubGridRow";

interface IProps{
    id: string;
}

const RequestsSubGrid : React.FC<IProps> = ({id}) => {
    const {requestsStore:{getDeviceRequests}} = useStore();
    const [requests, setRequests] = useState<IRequestItem[]>([]);

    useEffect(()=>{
        setRequests(getDeviceRequests(id));
    },[])
  return (
    <>
        <div className='data-grid request-sub-grid'>
            <div className='data-grid-row heading-row'>
                <div className='data-grid-col'>Date</div>
                <div className='data-grid-col'>Mobile No</div>
                <div className='data-grid-col'>App Name</div>
                <div className='data-grid-col'>Type</div>
                <div className='data-grid-col'>Status</div>
                <div className='data-grid-col'>Processed On</div>
            </div>
            <div className='data-grid-row'>
                {requests.length === 0 ? (
                    <div className="data-grid-col">No records</div>
                ) : (
                    requests.map((req,i) => (
                        <RequestsSubGridRow key={i} request={req} />
                    ))
                )}
            </div>
        </div>
    </>
  )
}

export default observer(RequestsSubGrid);