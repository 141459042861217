
import { reaction, runInAction, makeAutoObservable } from "mobx";
import { ILoggedUser, ILogin } from "../models/user";
import agent from "../api/agent";
import { IApiResponseStatus } from "../models/apiresponse";

export default class CommonStore {
  
    appLoading = false;
    apiCalled = false;
    apiReturned = false;
    apiError = false;
    lastError = "";

    token: string | null = window.localStorage.getItem("alm_jwt");
    prevRoute: string | null = window.sessionStorage.getItem("alm_pr");
    
    user: ILoggedUser | null = null;  
    constructor() {
            makeAutoObservable(this);
            reaction(
                () => this.token,
                (token) => {
                    if (token) {
                    window.localStorage.setItem("alm_jwt", token);
                    } else {
                    window.localStorage.removeItem("alm_jwt");
                    }
                }
            );
            reaction(
                () => this.prevRoute,
                (pr) => {
                    if (pr) {
                    window.sessionStorage.setItem("alm_pr", pr);
                    } else {
                    window.sessionStorage.removeItem("alm_pr");
                    }
                }
            );
    }

  

    get isLoggedIn() {
            return !!this.user;
    }

    setPrevRoute = (pRoute: string) =>{
        if(this.prevRoute !== pRoute)
        this.prevRoute = pRoute;
    }

    setToken = (token: string | null) => {
        this.token = token;
    };

    setAppLoaded = (loaded: boolean = true) => {
        this.appLoading = !loaded;
    }

    resetLastError = () => {
        this.lastError = "";
    }
    
    setApiCalled = (called: boolean = true) => {
        this.apiCalled = called;
        runInAction(() => {
            this.apiReturned = !called;
            if(called)
                this.resetLastError();
        });
    }
    
    setLastError = (error: string) => {
        this.apiReturned = true;
        runInAction(() => {
            this.apiError = true;
            this.lastError = error;
        })
    }



  login =async (login: ILogin) => {
    this.setApiCalled();
    agent.User.login(login)
                .then((response) => {
                    if(response.status === IApiResponseStatus.ResponseOK){
                        runInAction(() => {
                            this.user = response.result;
                            this.token = response.result.token;
                        })
                        if(this.prevRoute){
                            switch(this.prevRoute){
                                case '/apps':
                            }
                        }
                    } 
                    else
                        throw response.error.description;
                    this.setApiCalled(false);
                });
  }

  logoff = () => {
    runInAction(()=>{
        this.user = null;
        this.token = null;
    })
  }
  
  getUser = async () => {
    this.setApiCalled();
    var response = await agent.User.currentUser();
    if(response.status === IApiResponseStatus.ResponseOK){
        runInAction(() => {
            this.user = response.result;
            this.token = response.result.token;
        })
    }
    this.setApiCalled(false);
  }

}
