import React, { ReactNode } from 'react'
import { FaTimesCircle } from 'react-icons/fa';
interface Props{
    title: string;
    showDialog: boolean;
    onClose: () => void;
    children?: ReactNode;
}
const LookUpDialog = ({title,showDialog,onClose,children}:Props) => {
  return (
    <div className={`dialog-wrapper ${showDialog ? "dialog-show" : ""}`}>
        <div className='dialog-box lookup-dialog'>
            <div className="dialog-title">
                <h2 data-alter-title>{title}</h2>
                <span className="dialog-close">
                    <FaTimesCircle onClick={onClose} />
                </span>
            </div>
            <div className='dialog-body overflow-scroll'>
                {children && children}
            </div>
        </div>
    </div>
  )
}

export default LookUpDialog