import { ReactElement } from "react";
import { useField } from "formik";

interface Props{
    name: string;
    placeholder: string;
    label?: string;
    icon?: ReactElement;
    type?: string;
    disabled?: boolean;
}
export default function InputGroup(props: Props){
    const [field, meta] = useField(props.name);

    return (
        <div className="form-input-group">
            {props.icon && (
                <div className="input-icon">
                    {props.icon}
                </div>
            )}
            {props.label && (
                <label className="form-input-label">{props.label}</label>
            )}
            <input className={`form-input-field ${(meta.touched && !!meta.error) ? 'input-invalid' : ''}`} 
                    {...field}
                    placeholder={props.placeholder}
                    type={props.type ? props.type :'string'}
                    disabled={props.disabled}
             />
             {meta.touched && meta.error ? (
                 <span className="input-error">{meta.error}</span>
             ) : null}
        </div>
    )
}