
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { IApiResponseStatus, IDeviceEntity, ILicenseKeyParams } from "../models";
import { store } from "./store";

export default class DevicesStore {

  devices: IDeviceEntity[] = [];
  currentDevice: IDeviceEntity | undefined;

  searchTerm: string = '';
  deviceList: IDeviceEntity[] = [];
  devSortColumn = 2;
  devSortMode = true;

  constructor() {
    makeAutoObservable(this);
  }

  setDevSortColumn = (col: number) => {
    if(this.devSortColumn === col)
      runInAction(() => this.devSortMode = !this.devSortMode);
    else
      runInAction(() => {
        this.devSortColumn = col
        this.devSortMode = true;
      })
    this.sortDevices();
  }
  
  setSearchTerm = (search: string) => {
    this.searchTerm = search;
    this.sortDevices();
  }

  sortDevices = () => {
    if(this.devices.length === 0)
      this.deviceList = [];
    else{
      let devs = this.devices;
      if(this.searchTerm.length > 0)
        devs = devs.filter(r => r.mobileNo.includes(this.searchTerm));
      switch(this.devSortColumn){
         case 1:
             if(this.devSortMode)
               this.deviceList = devs.sort((a,b) => a.mobileNo > b.mobileNo ? 1 : -1);
             else
               this.deviceList = devs.sort((a,b) => a.mobileNo > b.mobileNo ? -1 : 1);
              break;
         case 2:
             if(this.devSortMode)
               this.deviceList = devs.sort((a,b) => a.appName > b.appName ? 1 : -1);
             else
               this.deviceList = devs.sort((a,b) => a.appName > b.appName ? -1 : 1);
            break;
         case 3:
             if(this.devSortMode)
               this.deviceList = devs.sort((a,b) => a.issuedOn && b.issuedOn && a.issuedOn > b.issuedOn ? 1 : -1);
             else
               this.deviceList = devs.sort((a,b) => a.issuedOn && b.issuedOn && a.issuedOn > b.issuedOn ? -1 : 1);
            break;
         case 4:
             if(this.devSortMode)
               this.deviceList = devs.sort((a,b) => a.validTill && b.validTill && a.validTill > b.validTill ? 1 : -1);
             else
               this.deviceList = devs.slice(0,10).sort((a,b) => a.validTill && b.validTill && a.validTill > b.validTill ? -1 : 1);
            break;
         default:
               this.deviceList = devs;
               break;
       };
    }
  }

  loadDevices = async () =>{
    store.commonStore.setApiCalled();
    let response = await agent.Devices.all()
    if(response.status === IApiResponseStatus.ResponseOK){
      runInAction(() => this.devices = response.result);
      this.sortDevices();
    }
    else
      store.commonStore.setLastError(response.error.description);
    store.commonStore.setApiCalled(false);
  }

  get TopDevices(){
    if(this.devices.length === 0)
      return [];
     switch(this.devSortColumn){
        case 1:
            if(this.devSortMode)
              return this.devices.slice(0,5).sort((a,b) => a.mobileNo > b.mobileNo ? 1 : -1);
            else
              return this.devices.slice(0,5).sort((a,b) => a.mobileNo > b.mobileNo ? -1 : 1);
        case 2:
            if(this.devSortMode)
              return this.devices.slice(0,5).sort((a,b) => a.appName > b.appName ? 1 : -1);
            else
              return this.devices.slice(0,5).sort((a,b) => a.appName > b.appName ? -1 : 1);
        case 3:
            if(this.devSortMode)
              return this.devices.slice(0,5).sort((a,b) => a.issuedOn && b.issuedOn && a.issuedOn > b.issuedOn ? 1 : -1);
            else
              return this.devices.slice(0,5).sort((a,b) => a.issuedOn && b.issuedOn && a.issuedOn > b.issuedOn ? -1 : 1);
        case 4:
            if(this.devSortMode)
              return this.devices.slice(0,5).sort((a,b) => a.validTill && b.validTill && a.validTill > b.validTill ? 1 : -1);
            else
              return this.devices.slice(0,5).sort((a,b) => a.validTill && b.validTill && a.validTill > b.validTill ? -1 : 1);
        default:
              return this.devices.slice(0,5);
      };
  }
  
  getAppDevices = (id:string) => {
    if(this.devices.length === 0)
      return [];
    return this.devices.filter(d => d.appId === id);
  }

  getDevice = async (id: string) => {
    store.commonStore.setApiCalled();
    let response = await agent.Devices.get(id);
    if(response.status === IApiResponseStatus.ResponseOK)
      runInAction(()=>this.currentDevice = response.result);
    else
      store.commonStore.setLastError(response.error.description);
    store.commonStore.setApiCalled(false);
  }
  
  enableDevice = async (id: string) => {
    store.commonStore.setApiCalled();
    let response = await agent.Devices.enable(id)
    if(response.status === IApiResponseStatus.ResponseError)
      store.commonStore.setLastError(response.error.description);
    store.commonStore.setApiCalled(false);
  }

  disableDevice = async (id: string) => {
    store.commonStore.setApiCalled();
    let response = await agent.Devices.disable(id)
    if(response.status === IApiResponseStatus.ResponseError)
      store.commonStore.setLastError(response.error.description);
    store.commonStore.setApiCalled(false);
  }

  generateLicense = async (params: ILicenseKeyParams) => {
    store.commonStore.setApiCalled();
    let response = await agent.Devices.generateLicense(params)
    if(response.status === IApiResponseStatus.ResponseOK){
      let deviceId = store.requestsStore.updateLicenseKey(params.requestId,response.result);
      if(deviceId.length > 0){
        let device = this.devices.find(d => d.id === deviceId);
        if(device){
          device.license = response.result;
          runInAction(() => this.devices = [...this.devices,device!]);
        }
      }
    }
    store.commonStore.setApiCalled(false);
  }

  pushLicense = async (id: string) => {
    store.commonStore.setApiCalled();
    let response = await agent.Devices.pushLicense(id)
    if(response.status === IApiResponseStatus.ResponseError){
      alert(`Push failed! ${response.error.description}`);
      store.commonStore.setLastError(response.error.description);
    }
    else
      alert('Push notification sent successfully!');
    store.commonStore.setApiCalled(false);
  }
}