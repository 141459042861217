import { observer } from "mobx-react-lite";
import { GiHouseKeys } from "react-icons/gi"
import {Navbar} from "."
import { useStore } from "../../stores/store"
const Header = () => {
  const {commonStore:{isLoggedIn}} = useStore();
  return (
    <div className='header'>
        <div className='navbar-brand'>
            <GiHouseKeys className='navbar-logo' />
            <h1 className="logo-title">Axcess License Manager</h1>
        </div>
        {isLoggedIn && <Navbar />}
      </div>
  )
}

export default observer(Header);