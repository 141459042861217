import { IAppsEntity } from "../../models"
import { BsToggle2On, BsToggle2Off } from 'react-icons/bs';
import { CgListTree } from 'react-icons/cg';
import { FiDatabase } from 'react-icons/fi';
import { MdViewModule } from 'react-icons/md';
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { DeviceSubGrid } from "../Devices";
import AppModuleList from "./AppModuleList";
import AppDatabaseList from "./AppDatabaseList";
import { truncate } from "../../utils/Utils";

interface IProps{
    app: IAppsEntity;
}

const TopApplicationsGridRow : React.FC<IProps> = ({app}) => {
    const [showDevices,setShowDevices] = useState(false);
    const [showDatabase,setShowDatabase] = useState(false);
    const [showModules,setShowModules] = useState(false);
    
    const handleShowDatabase = (show: boolean) => {
        setShowDatabase(show)
        if(show){
            setShowModules(false);
            setShowDevices(false);
        }
    }
    const handleShowModules = (show: boolean) => {
        setShowModules(show)
        if(show){
            setShowDatabase(false);
            setShowDevices(false);
        }
    }
    const handleShowDevices = (show: boolean) => {
        setShowDevices(show)
        if(show){
            setShowModules(false);
            setShowDatabase(false);
        }
    }


    return (
    <div className="data-grid-row">
        <div key={`${app.id}_1`} className='data-grid-col'>{app.appName}</div>
        <div key={`${app.id}_2`} className='data-grid-col'>{truncate(app.endPoint,40)}</div>
        <div key={`${app.id}_3`} className='data-grid-col'>{truncate(app.passcode,25)}</div>
        <div key={`${app.id}_4`} className='data-grid-col'>{app.isActive ? <BsToggle2On className="icon-no-cursor icon-status-active" /> : <BsToggle2Off className="icon-no-cursor icon-status-inactive" /> }</div>
        <div key={`${app.id}_5`} className='data-grid-col icon-col'><FiDatabase onClick={()=>handleShowDatabase(!showDatabase)} /></div>
        <div key={`${app.id}_6`} className='data-grid-col icon-col'><MdViewModule onClick={()=>handleShowModules(!showModules)} /></div>
        <div key={`${app.id}_7`} className='data-grid-col icon-col'><CgListTree onClick={()=>handleShowDevices(!showDevices)} /></div>
        {showDevices && 
        <div className="data-subgrid-row">
            <DeviceSubGrid key={app.id} id={app.id} />
        </div>}
        {showDatabase && 
        <div className="data-subgrid-row">
            <AppDatabaseList key={app.id} id={app.id} />
        </div>}
        {showModules && 
        <div className="data-subgrid-row">
            <AppModuleList key={app.id} id={app.id} />
        </div>}
    </div>
  )
}

export default observer(TopApplicationsGridRow);