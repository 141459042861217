import { observer } from "mobx-react-lite"
import { BsSortDown, BsSortUpAlt } from "react-icons/bs";
import { TopApplicationsGridRow } from ".";
import { useStore } from './../../stores/store';
const TopApplicationsGrid = () => {
    const {appsStore:{TopApps,setAppSortColumn,appSortColumn,appSortMode}} = useStore();

  return (
    <>
        <div className='data-grid top-apps-grid'>
            <div className='data-grid-row heading-row'>
                <div onClick={() => setAppSortColumn(1)} className='data-grid-col'><span>App Name</span>{appSortColumn === 1 && (appSortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div onClick={() => setAppSortColumn(2)} className='data-grid-col'><span>Endpoint</span>{appSortColumn === 2 && (appSortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div onClick={() => setAppSortColumn(3)} className='data-grid-col'><span>Passcode</span>{appSortColumn === 3 && (appSortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div className='data-grid-col'>Status</div>
                <div className='data-grid-col'>Database</div>
                <div className='data-grid-col'>Modules</div>
                <div className='data-grid-col'>Devices</div>
            </div>
                {TopApps.length === 0 ? (
                    <div className="data-grid-full-row">No records</div>
                ) : (
                    TopApps.map((app,i) => (
                        <TopApplicationsGridRow key={i} app={app}  />
                    ))
                )}
            </div>
    </>
  )
}

export default observer(TopApplicationsGrid);