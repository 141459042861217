import { IAppsEntity } from "../../models"
import {ApplicationGridRow} from "."
import { observer } from "mobx-react-lite"
import { BsSortDown, BsSortUpAlt } from "react-icons/bs";
import { useStore } from './../../stores/store';

interface IProps{
    apps: IAppsEntity[];
    handleStatus: (id: string) => void; 
    handleEdit: (app: IAppsEntity) => void;
};
const ApplicationGrid : React.FC<IProps> = ({apps,handleStatus,handleEdit}) => {
    const {appsStore:{appSortColumn,appSortMode,setAppSortColumn}} = useStore();
  return (
    <>
        <div className='data-grid apps-grid'>
            <div className='data-grid-row heading-row'>
                <div onClick={() => setAppSortColumn(1)} className='data-grid-col'><span>App Name</span>{appSortColumn === 1 && (appSortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div onClick={() => setAppSortColumn(2)} className='data-grid-col'><span>Endpoint</span>{appSortColumn === 2 && (appSortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div onClick={() => setAppSortColumn(3)} className='data-grid-col'><span>Passcode</span>{appSortColumn === 3 && (appSortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div className='data-grid-col'>Status</div>
                <div className='data-grid-col'>Database</div>
                <div className='data-grid-col'>Modules</div>
                <div className='data-grid-col'>Devices</div>
                <div className='data-grid-col'>Edit</div>
            </div>
            <div className='data-grid-row'>
                {apps.length === 0 ? (
                    <div className="data-grid-full-row">No records</div>
                ) : (
                    apps.map((app,i) => (
                        <ApplicationGridRow key={i} app={app} editApp={handleEdit} toggleStatus={handleStatus} />
                    ))
                )}
            </div>
        </div>
    </>
  )
}

export default observer(ApplicationGrid);