import { IDeviceEntity } from "../../models"
import { ToDDMMYY, truncate } from "../../utils/Utils";
import { BsToggles2 } from 'react-icons/bs';
import { VscRepoPush } from 'react-icons/vsc'
import { CgListTree } from 'react-icons/cg'
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { RequestsSubGrid } from "../Requests";

interface IProps{
    device: IDeviceEntity;
    handleStatus: (id: string) => void;
    handlePush: (is: string) => void;
}

const DeviceGridRow : React.FC<IProps> = ({device, handleStatus, handlePush}) => {
    const [showRequests,setShowRequests] = useState(false);
  return (
    <>
        <div key={`${device.id}_1`} className='data-grid-col'>{device.mobileNo}</div>
        <div key={`${device.id}_2`} className='data-grid-col'>{device.appName}</div>
        <div key={`${device.id}_3`} className='data-grid-col'>{truncate(device.license)}</div>
        <div key={`${device.id}_4`} className='data-grid-col'>{device.issuedOn ? ToDDMMYY(new Date(device.issuedOn)) : "NA"}</div>
        <div key={`${device.id}_5`} className='data-grid-col'>{device.validTill ? ToDDMMYY(new Date(device.validTill)) : 'NA'}</div>
        <div key={`${device.id}_6`} className='data-grid-col'>{device.isEnabled ? 'Enabled' : 'Disabled'}</div>
        <div key={`${device.id}_7`} className='data-grid-col'><BsToggles2 onClick={() => handleStatus(device.id)}/></div>
        <div key={`${device.id}_8`} className={`data-grid-col ${device.license ? '' : 'icon-disabled' }`}><VscRepoPush onClick={() => handlePush(device.id)}/></div>
        <div key={`${device.id}_9`} className='data-grid-col'><CgListTree onClick={()=>setShowRequests(!showRequests)} /></div>
        {showRequests && 
        <div className="data-subgrid-row">
            <RequestsSubGrid key={device.id} id={device.id} />
        </div>}
    </>
  )
}

export default observer(DeviceGridRow);