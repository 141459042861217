import { IRequestItem } from "../../models"
import { RequestProcessDialog, RequestsGridRow} from ".";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { BsSortDown, BsSortUpAlt } from "react-icons/bs";


interface IProps{
    requests: IRequestItem[];
}

const RequestsGrid : React.FC<IProps> = ({requests}) => {
    const {devicesStore:{pushLicense},requestsStore:{setReqSortColumn,reqSortColumn,reqSortMode}} = useStore()
    const [showDialog, setShowDialog] = useState(false);
    const [currentReq, setCurrentReq] = useState<IRequestItem | undefined>();

    const handleIssue = (id: string) =>{
            let req = requests.find(r => r.id === id);
            if(req){
                setCurrentReq(req);
                setShowDialog(true);
            }
        }
  return (
    <>
        <div className='data-grid request-grid'>
            <div className='data-grid-row heading-row'>
                <div onClick={() => setReqSortColumn(1)} className='data-grid-col'><span>Date</span>{reqSortColumn === 1 && (reqSortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div onClick={() => setReqSortColumn(2)} className='data-grid-col'><span>Mobile No</span>{reqSortColumn === 2 && (reqSortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div onClick={() => setReqSortColumn(3)} className='data-grid-col'><span>App Name</span>{reqSortColumn === 3 && (reqSortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div onClick={() => setReqSortColumn(4)} className='data-grid-col'><span>Type</span>{reqSortColumn === 4 && (reqSortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div onClick={() => setReqSortColumn(5)} className='data-grid-col'><span>Status</span>{reqSortColumn === 5 && (reqSortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div onClick={() => setReqSortColumn(6)} className='data-grid-col'><span>Processed On</span>{reqSortColumn === 6 && (reqSortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div className='data-grid-col'>Issue</div>
                <div className='data-grid-col'>Push</div>
            </div>
            <div className='data-grid-row'>
                {requests.length === 0 ? (
                    <div className="data-grid-full-row">No records</div>
                ) : (
                    requests.map((req,i) => (
                        <RequestsGridRow key={i} request={req} handleIssue={handleIssue} handlePush={() => req.deviceId && pushLicense(req.deviceId)} />
                    ))
                )}
            </div>
        </div>
        {showDialog && currentReq && <RequestProcessDialog request={currentReq} showDialog={showDialog} hideDialog={()=>setShowDialog(false)}  />}
    </>
  )
}

export default observer(RequestsGrid);