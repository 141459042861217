import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineCheck } from "react-icons/ai";
import { IAppModule, IAppModuleEntity } from "../../models";
import { useStore } from "../../stores/store";
import { InputPrompt } from "../common";

interface IProps{
    id: string;
}

const AppModuleList : React.FC<IProps> = ({id}) => {
    const {commonStore:{apiCalled,apiError,lastError}, appsStore:{appModules,loadAppModules,getAppModules,addModule,enableModule,disableModule}} = useStore();
    const [showAdd, setShowAdd] = useState(false);
    const [moduleList, setModuleList] = useState<IAppModuleEntity[]>([])
    useEffect(()=>{
        if(appModules.length === 0)
            loadAppModules();
    })

    useEffect(() => {
        if(appModules.length > 0)
            setModuleList(getAppModules(id));
    },[appModules,setModuleList,getAppModules,id])

    const handleAddNew = (moduleName: string ) => {
        let module: IAppModule = {
            appId: id,
            moduleName: moduleName
        }
        addModule(module);
        setShowAdd(false);
    }
    if(appModules.length === 0 && !apiCalled)
        if(apiError)
            return <span>{lastError}</span>
    return (
    
    <div className="data-list-container">
        <div className="data-list-header">
            <h2 className="data-list-title">Modules</h2>
            <button type="button" className="btn btn-tiny" onClick={()=>setShowAdd(true)}>Add</button>
        </div>
        <ul>
        {moduleList.length === 0 ? (
            <span>No Modules</span>
        ) : (
            <>
                {moduleList.map((am) => (
                    <li key={am.id}><span className={am.isEnabled ? '' : 'inactive'}>{am.moduleName}</span>{am.isEnabled ? <AiOutlineClose className="icon-status-inactive" onClick={()=>disableModule(am.id)} /> : <AiOutlineCheck className="icon-status-active" onClick={()=>enableModule(am.id)} />}</li>
                ))}
            </>
        )}
        </ul>
        {showAdd && <InputPrompt title="Add Module" label="Module Name" minLength={3} maxLength={50} onCancel={()=>setShowAdd(false)} onSave={handleAddNew} showPrompt={showAdd} value='' />}
    </div>
  )
}

export default observer(AppModuleList);