import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IApiResponse, IApiResponseT, IAppDatabase, IAppDatabaseEntity, IAppModule, IAppModuleEntity, IApps, IAppsEntity, IDeviceEntity, IRequestEntity, IRequestItem, ILoggedUser, ILogin, kvp, ILicenseKeyParams } from "../models";
import { DriverEntity } from "../models/drivers";
import { LocationLookUpEntity } from "../models/locationLookUp";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = window.localStorage.getItem("alm_jwt");
    if (token) {
        if (config.headers === undefined) {
             config.headers = {};
        }
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const responseBody = (response: AxiosResponse) => response.data;

const request = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>  axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody)
};

const User = {
  login: (user: ILogin) : Promise<IApiResponseT<ILoggedUser>> => request.post("/user/login",user),
  currentUser: () : Promise<IApiResponseT<ILoggedUser>> => request.get("/user/current"),
}

const Requests = {
  all: () : Promise<IApiResponseT<IRequestItem[]>> => request.get('/requests'),
  get: (id: string) : Promise<IApiResponseT<IRequestEntity>> => request.get(`/requests/${id}`),
  update: (id: string, status: number) : Promise<IApiResponse> => request.put(`/requests/${id}/${status}`,{}),
  getTypes: () : Promise<IApiResponseT<kvp<number,string>[]>> => request.get('/requests/type/all'),
  addtype: (type: kvp<number,string>) : Promise<IApiResponse> => request.post("/requests/type",type),
  deltype: (id: number) : Promise<IApiResponse> => request.del(`/requests/type/${id}`),
  getStatuses: () : Promise<IApiResponseT<kvp<number,string>[]>> => request.get('/requests/status/all'),
  addstatus: (status: kvp<number,string>) : Promise<IApiResponse> => request.post("/requests/status",status),
  delstatus: (id: number) : Promise<IApiResponse> => request.del(`/requests/status/${id}`),
}

const Devices = {
  all: () : Promise<IApiResponseT<IDeviceEntity[]>> => request.get("/devices"),
  get: (id: string) : Promise<IApiResponseT<IDeviceEntity>> => request.get(`/devices/${id}`),
  enable: (id: string) : Promise<IApiResponse> => request.put(`/devices/enable/${id}`,{}),
  disable: (id: string) : Promise<IApiResponse> => request.put(`/devices/disable/${id}`,{}),
  generateLicense: (params: ILicenseKeyParams) : Promise<IApiResponseT<string>> => request.post('/devices/license',params),
  pushLicense: (id: string) : Promise<IApiResponse> => request.get(`/devices/license/push?id=${id}`)
}

const Apps = {
 all: () : Promise<IApiResponseT<IAppsEntity[]>> => request.get("/apps"),
 get: (id: string) : Promise<IApiResponseT<IAppsEntity>> => request.get(`/apps/${id}`),
 new: (apps: IApps) : Promise<IApiResponseT<string>> => request.post(`/apps/new`,apps),
 update: (id: string, apps: IApps) : Promise<IApiResponse> => request.put(`/apps/update/${id}`,apps),
 activate: (id: string) : Promise<IApiResponse> => request.put(`/apps/activate/${id}`,{}),
 deactivate: (id: string) : Promise<IApiResponse> => request.put(`/apps/deactivate/${id}`,{}),
 getdrivers: (db: string) : Promise<IApiResponseT<DriverEntity[]>> => request.get(`/drivers/${db}`),
 getLocations: (db: string) : Promise<IApiResponseT<LocationLookUpEntity[]>> => request.get(`/locations/${db}`),
}

const AppModules = {
 all: () : Promise<IApiResponseT<IAppModuleEntity[]>> => request.get('/apps/modules'),
 new: (module: IAppModule) : Promise<IApiResponseT<IAppModuleEntity>> => request.post(`/apps/modules/new`,module),
 update: (id: number, module: IAppModule) : Promise<IApiResponse> => request.put(`/apps/modules/update/${id}`,module),
 enable: (id: number) : Promise<IApiResponse> => request.put(`/apps/modules/enable/${id}`,{}),
 disable: (id: number) : Promise<IApiResponse> => request.put(`/apps/modules/disable/${id}`,{}),
}

const AppDb = {
 all: () : Promise<IApiResponseT<IAppDatabaseEntity[]>> => request.get('/apps/database'),
 new: (appDb: IAppDatabase) : Promise<IApiResponseT<IAppDatabaseEntity>> => request.post(`/apps/database/new`,appDb),
 update: (id: number, appDb: IAppDatabase) : Promise<IApiResponse> => request.put(`/apps/database/update/${id}`,appDb),
 delete: (id: number) : Promise<IApiResponse> => request.del(`/apps/database/delete/${id}`),
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { User, Requests, Devices, Apps, AppModules, AppDb }