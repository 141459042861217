import { IDeviceEntity } from "../../models"
import { ToDDMMYY, truncate } from "../../utils/Utils";
import { observer } from "mobx-react-lite";

interface IProps{
    device: IDeviceEntity;
}

const DeviceSubGridRow : React.FC<IProps> = ({device}) => {
  return (
    <>
        <div key={`${device.id}_1`} className='data-grid-col'>{device.mobileNo}</div>
        <div key={`${device.id}_2`} className='data-grid-col'>{device.appName}</div>
        <div key={`${device.id}_3`} className='data-grid-col'>{truncate(device.license)}</div>
        <div key={`${device.id}_4`} className='data-grid-col'>{device.issuedOn ? ToDDMMYY(new Date(device.issuedOn)) : "NA"}</div>
        <div key={`${device.id}_5`} className='data-grid-col'>{device.validTill ? ToDDMMYY(new Date(device.validTill)) : 'NA'}</div>
        <div key={`${device.id}_6`} className='data-grid-col'>{device.isEnabled ? 'Enabled' : 'Disabled'}</div>
    </>
  )
}

export default observer(DeviceSubGridRow);