import React from 'react'
import { observer } from "mobx-react-lite";
import { LoginBox } from "../components/common";
import { Dashboard } from "../components/Home";
import { useStore } from "../stores/store"

const HomePage = () => {
  const {commonStore:{isLoggedIn}} = useStore();
  if(isLoggedIn)
      return <Dashboard />
  return <LoginBox />
}

export default observer(HomePage);