import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import AppsStore from './appsStore';
import DevicesStore from './devicesStore';
import RequestsStore from './requestsStore';

interface Store {
    commonStore: CommonStore;
    appsStore: AppsStore;
    devicesStore: DevicesStore;
    requestsStore: RequestsStore;
}

export const store: Store = {
    commonStore: new CommonStore(),
    appsStore: new AppsStore(),
    devicesStore: new DevicesStore(),
    requestsStore: new RequestsStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}