import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { IDeviceEntity } from '../../models';
import { useStore } from '../../stores/store'
import DeviceSubGridRow from './DeviceSubGridRow';
interface IProps{
    id: string;
}
const DeviceSubGrid : React.FC<IProps> = ({id}) => {
    const {devicesStore:{getAppDevices}} = useStore();
    const [devices,setDevices] = useState<IDeviceEntity[]>([]);

    useEffect(()=>{
        setDevices(getAppDevices(id));
    },[])
 return (
     <>
        <div className='data-grid device-sub-grid'>
            <div className='data-grid-row heading-row'>
                <div className='data-grid-col'>Mobile No</div>
                <div className='data-grid-col'>App Name</div>
                <div className='data-grid-col'>License</div>
                <div className='data-grid-col'>Issued On</div>
                <div className='data-grid-col'>Valid Till</div>
                <div className='data-grid-col'>Status</div>
            </div>
            <div className='data-grid-row'>
                {devices.length === 0 ? (
                    <div className="data-grid-col">No records</div>
                ) : (
                    devices.map((dev,i) => (
                        <DeviceSubGridRow key={i} device={dev} />
                    ))
                )}
            </div>
        </div>
    </>
  )
}

export default observer(DeviceSubGrid)