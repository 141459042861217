import { observer } from "mobx-react-lite";
import { NavLink } from "react-router-dom"
import { useStore } from "../../stores/store"

const Navbar = () => {
  const {commonStore:{user,logoff}} = useStore();
  const handleLogOff = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    logoff();
  }
  return (
    <>
      <input type="checkbox" id="navbar-toggler" className='navbar-toggler' />
      <nav>
          <ul className="navbar-menu-main">
            <li><NavLink  to={'/'}>Dashboard</NavLink></li>
            <li><NavLink to={'/applications'}>Applications</NavLink></li>
            <li><NavLink to='/requests'>Requests</NavLink></li>
            <li><NavLink to='/devices'>Devices</NavLink></li>
            {user && (
              <li className="nav-dropdown">
                  <a href="#">{user.username}</a>
                  <div className="navbar-menu-sub">
                      <ul>
                          {/*<li><NavLink to='/changepassword'>Change Password</NavLink></li>*/}
                          <li><a href="#" onClick={handleLogOff} >Log Off</a></li>
                      </ul>
                  </div>
              </li>
            )}
          </ul>
        </nav>
          <label htmlFor='navbar-toggler' className="navbar-hamburger">
            <span></span>
        </label>
    </>
  )
}

export default observer(Navbar);